<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            label="공사현장명"
            name="plantName"
            v-model="searchParam.plantName">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="공사기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="projectTerms"
            v-model="searchParam.projectTerms"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-select
            :comboItems="finishFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="finishFlag"
            label="공사 완료여부"
            v-model="searchParam.finishFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <c-table
          ref="table"
          title="공사현장 목록"
          tableId="table"
          :columns="grid.columns"
          :data="grid.data"
          @linkClick="linkClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable" label="등록" icon="add" @btnClicked="openPop" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
        <c-dialog :param="popupOptions"></c-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'project-manage',
  data() {
    return {
      finishFlagItems: [
        { code: 'Y', codeName: '공사완료' },
        { code: 'N', codeName: '진행중' },
      ],
      searchParam: {
        projectTerms: [],
        plantName: '',
        vendorCd: '',
        finishFlag: null,
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '공사현장 명',
            align: 'center',
            style: 'width:300px',
            sortable: true,
            type: 'link',
          },
          {
            name: 'projectDt',
            field: 'projectDt',
            label: '공사기간',
            style: 'width:120px',
            align: 'center',
            sortable: true,
          },
          // {
          //   name: 'projectAddress',
          //   field: 'projectAddress',
          //   label: '공사현장 소재지',
          //   align: 'left',
          //   sortable: true,
          // },
          {
            name: 'projectCost',
            field: 'projectCost',
            label: '공사금액(원)',
            align: 'right',
            style: 'width:150px',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'projectSafetyCost',
            field: 'projectSafetyCost',
            label: '안전관리비(원)',
            style: 'width:150px',
            align: 'right',
            type: 'cost',
            sortable: true,
          },
          {
            name: 'finishFlagName',
            field: 'finishFlagName',
            label: '공사완료여부',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      headerUrl: '',
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.searchParam.companyCd = this.$store.getters.user.companyCd;

      if (this.$store.getters.user.innerFlag === 'N') {
        this.searchParam.vendorCd = this.$store.getters.user.deptCd;
        this.editable = false;
      } else {
        this.searchParam.vendorCd = '';
        this.editable = this.$route.meta.editable;
      }
      this.listUrl = selectConfig.pjm.info.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.openPop(row);
    },
    openPop(row) {
      this.popupOptions.title = '공사현장 상세';
      this.popupOptions.param = {
        plantCd: row ? row.plantCd : '',
      };
      this.popupOptions.target = () => import(`${"./projectDetail.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.isFull = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
